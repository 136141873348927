import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Image from './Image';

const Hero = () => (
  <StaticQuery
    query={graphql`
      query HeroQuery {
        site {
          siteMetadata {
            address
            addressLink
            email
            phone
            phoneFormatted
            title
          }
        }
      }
    `}
    render={(data) => (
      <div id="hero" className="flex flex-wrap text-white bg-black">
        <div className="w-full px-4 py-2 md:p-2 md:pl-4 md:w-1/2">
          <div className="flex items-center justify-center w-full px-4 py-24 border border-green-600">
            <div className="max-w-md">
              <h1 className="text-4xl leading-none text-green-700 uppercase font-display">
                Mobile
                <br /> {data.site.siteMetadata.title}
              </h1>
              <div className="mb-6 text-3xl font-thin leading-tight text-white md:text-4xl">
                <p className="leading-none">Minor Panel &amp; Paint Expert</p>
                <ul className="text-2xl leading-snug">
                  <li>Save money</li>
                  <li>Best prices</li>
                  <li>Free quotes</li>
                </ul>
                <div className="text-base">
                  <h3 className="m-0 text-green-600 font-display">Email:</h3>{' '}
                  <p>
                    <a
                      className="text-white underline hover:text-green-600"
                      href={`mailto:${data.site.siteMetadata.email}?subject=Email%20from%20website&body=Please%20attach%20photos%20of%20your%20vehicle%20below%20if%20you%20would%20like%20a%20quote%20and%20we%20will%20respond%20as%20soon%20as%20we%20can.%0A%0AYour%20name%3A%0A%0APhone%20number%3A%0A%0AYour%20question%3A`}
                    >
                      {data.site.siteMetadata.email}
                    </a>
                  </p>
                  <h3 className="m-0 text-green-600 font-display">Phone:</h3>{' '}
                  <p>
                    <a
                      className="text-white underline hover:text-green-600"
                      href={`tel:${data.site.siteMetadata.phone}`}
                    >
                      {data.site.siteMetadata.phoneFormatted}
                    </a>
                  </p>
                  <h3 className="m-0 text-green-600 font-display">Address:</h3>{' '}
                  <p>
                    <a
                      className="text-white underline hover:text-green-600"
                      href={`${data.site.siteMetadata.addressLink}`}
                    >
                      MOBILE - We come to you.
                    </a>
                  </p>
                </div>
              </div>
              <p className="pb-4 text-center">
                <a
                  className="px-6 py-2 text-sm leading-none text-white uppercase bg-black border border-2 border-white border-green-600 hover:bg-green-600 font-display hover:no-underline hover:text-black"
                  href="/#contact"
                >
                  Free quote
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="flex w-full h-64 px-4 py-2 overflow-hidden md:h-auto md:p-2 md:pr-4 md:w-1/2">
          <div className="relative w-full h-full border border-green-600">
            <div className="image-container">
              <Image />
            </div>
          </div>
        </div>
      </div>
    )}
  />
);

export default Hero;
