import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import LazyLoad from 'react-lazyload';

import Image from './Image';

const ContactInfo = () => (
  <StaticQuery
    query={graphql`
      query ContactInfoQuery {
        site {
          siteMetadata {
            address
            addressLink
            title
            facebook
            email
            phone
            phoneFormatted
          }
        }
      }
    `}
    render={(data) => (
      <div id="contact" className="flex flex-wrap-reverse text-white bg-black">
        <div className="flex w-full px-4 py-2 overflow-hidden h-128 md:h-auto md:p-2 md:pl-4 md:w-1/2">
          <div className="relative w-full h-full border border-green-600">
            <div className="image-container">
              <Image />
            </div>
          </div>
        </div>
        <div className="w-full px-4 py-2 md:p-2 md:pr-4 md:w-1/2">
          <div className="flex items-center justify-center w-full px-4 py-24 border border-green-600">
            <div className="max-w-md">
              <h2 className="py-2 m-0 text-4xl leading-none text-green-700 uppercase">
                Contact Info
              </h2>
              <p>
                Send us a photo and we will do a quote for you without the need
                to bring your car in!
              </p>
              <h3 className="m-0 text-green-600 font-display">Email:</h3>{' '}
              <p>
                <a
                  className="text-white underline hover:text-green-600"
                  href={`mailto:${data.site.siteMetadata.email}?subject=Email%20from%20website&body=Please%20attach%20photos%20of%20your%20vehicle%20below%20if%20you%20would%20like%20a%20quote%20and%20we%20will%20respond%20as%20soon%20as%20we%20can.%0A%0AYour%20name%3A%0A%0APhone%20number%3A%0A%0AYour%20question%3A`}
                >
                  {data.site.siteMetadata.email}
                </a>
              </p>
              <h3 className="m-0 text-green-600 font-display">Phone:</h3>{' '}
              <p>
                <a
                  className="text-white underline hover:text-green-600"
                  href={`tel:${data.site.siteMetadata.phone}`}
                >
                  {data.site.siteMetadata.phoneFormatted}
                </a>
              </p>
              <h3 className="m-0 text-green-600 font-display">
                Trading Hours:
              </h3>
              <table className="max-w-xs">
                <tbody>
                  <tr>
                    <td>Monday</td>
                    <td>7:00am&nbsp;–&nbsp;5:00pm</td>
                  </tr>
                  <tr>
                    <td>Tuesday</td>
                    <td>7:00am&nbsp;–&nbsp;5:00pm</td>
                  </tr>
                  <tr>
                    <td>Wednesday</td>
                    <td>7:00am&nbsp;–&nbsp;5:00pm</td>
                  </tr>
                  <tr>
                    <td>Thursday</td>
                    <td>7:00am&nbsp;–&nbsp;5:00pm</td>
                  </tr>
                  <tr>
                    <td>Friday</td>
                    <td>7:00am&nbsp;–&nbsp;5:00pm</td>
                  </tr>
                  <tr>
                    <td>Saturday</td>
                    <td>7:00am&nbsp;–&nbsp;5:00pm</td>
                  </tr>
                  <tr>
                    <td>Sunday</td>
                    <td>Closed</td>
                  </tr>
                </tbody>
              </table>
              <h3 className="m-0 text-green-600 font-display">Address:</h3>{' '}
              <p>
                <a
                  className="text-white underline hover:text-green-600"
                  href={`${data.site.siteMetadata.addressLink}`}
                >
                  MOBILE - We come to you.
                </a>
              </p>
              <LazyLoad height={256} once>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3402.9228600545316!2d152.91863291568856!3d-31.471308106060974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b9df8aab39ee629%3A0x6d54999efd6834dc!2s5%20Montague%20St%2C%20Port%20Macquarie%20NSW%202444%2C%20Australia!5e0!3m2!1sen!2stw!4v1678074632814!5m2!1sen!2stw"
                  title="location"
                  allowFullScreen
                  frameBorder={0}
                  className="w-full h-64 border border-green-600"
                />
              </LazyLoad>
            </div>
          </div>
        </div>
      </div>
    )}
  />
);

export default ContactInfo;
