import React from 'react';
import Image from './Image';

const Services = () => (
  <div id="services" className="bg-black flex flex-wrap text-white">
    <div className="px-4 py-2 md:p-2 md:pl-4 w-full md:w-1/2">
      <div className="border border-green-600 flex items-center justify-center px-4 py-24 w-full">
        <div className="max-w-md">
          <h2 className="leading-none m-0 py-2 text-4xl text-green-600 uppercase">
            Services
          </h2>
          <p>
            Why make an expensive and unnecessary insurance claim, see Dent
            Doctor first for a professional opinion.
          </p>
          <p>
            The big shops do the bigger jobs, Dent Doctor specialised in those
            smaller jobs, and doing them well!
          </p>
          <p>We can help you with the following:</p>
          <ul>
            <li>Colour matching</li>
            <li>Spray painting</li>
            <li>Minor panel-repairs</li>
            <li>Bumper repairs</li>
            <li>Stone chips and scratches</li>
            <li>Buffing and polishing</li>
            <li>Car makeovers</li>
          </ul>
        </div>
      </div>
    </div>
    <div
      className="flex h-64 md:h-auto overflow-hidden px-4 p
    y-2 md:p-2 md:pr-4 w-full md:w-1/2"
    >
      <div className="border border-green-600 h-full w-full relative">
        <div className="image-container">
          <Image />
        </div>
      </div>
    </div>
  </div>
);

export default Services;
