import React from 'react';
import Image from './Image';

import Form from '../Form';

const Contact = () => (
  <div className="bg-black flex flex-wrap text-white">
    <div className="px-4 py-2 md:p-2 md:pl-4 w-full md:w-1/2">
      <div className="flex items-center justify-center border border-green-600 px-4 py-24 w-full">
        <div className="max-w-md">
          <h2 className="leading-none m-0 py-2 text-4xl text-green-700 uppercase">
            Contact Form
          </h2>
          <Form />
        </div>
      </div>
    </div>
    <div className="flex h-128 md:h-auto overflow-hidden px-4 py-2 md:p-2 md:pr-4 w-full md:w-1/2">
      <div className="border border-green-600 h-full w-full relative">
        <div className="image-container">
          <Image />
        </div>
      </div>
    </div>
  </div>
);

export default Contact;
