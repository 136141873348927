import React from 'react';
import Image from './Image';

const About = () => (
  <div id="about" className="bg-black flex flex-wrap-reverse text-white">
    <div className="flex h-64 md:h-auto overflow-hidden px-4 py-2 md:p-2 md:pl-4 w-full md:w-1/2">
      <div className="border border-green-600 h-full w-full relative">
        <div className="image-container">
          <Image />
        </div>
      </div>
    </div>
    <div className="px-4 py-2 md:p-2 md:pr-4 w-full md:w-1/2">
      <div className="border border-green-600 flex items-center justify-center px-4 py-24 w-full">
        <div className="max-w-md">
          <h2 className="leading-none m-0 py-2 text-4xl text-green-600 uppercase">
            About
          </h2>
          <p>
            <span className="font-semibold text-green-600">
              Dent Doctor Port Macquarie
            </span>{' '}
            are your minor panel and paint experts. They are fully licensed
            spray-painters and panel-beaters.
          </p>
          <p>
            Owner-operator Mark Rose has over 35 years experience in the
            industry — giving you the peace of mind of knowing that you're
            leaving your car with a trusted professional.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default About;
