import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import About from '../components/About';
import Services from '../components/Services';
import ContactInfo from '../components/ContactInfo';
import ContactForm from '../components/ContactForm';

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={[
        `car dent repair port macquarie`,
        `dent doctor smash repairs port macquarie`,
        `dent repair port macquarie`,
        `panel beater port macquarie`,
        `panelbeater port macquarie`,
        `spray painter port macquarie`,
        `spraypainter port macquarie`,
        `the dent doctor port macquarie`,
      ]}
    />
    <Hero />
    <About />
    <Services />
    <ContactInfo />
    <ContactForm />
  </Layout>
);

export default IndexPage;
